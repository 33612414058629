import Header from "./Header/Header";
import React from "react";
import {Outlet} from "react-router-dom";

type LayoutProps = {
  isBackAuth: any
  signInWithEthereum: Function,
  setBalanceModal: Function,
    signOut: Function,
  isConnected: Boolean,
}

function Layout({
                  isBackAuth,
                  setBalanceModal,
                  signInWithEthereum,
                    signOut,
                  isConnected
                }: LayoutProps) {
  return (
    <div>
      <Header
        isBackAuth={isBackAuth}
        signInWithEthereum={signInWithEthereum}
        setBalanceModal={setBalanceModal}
        signOut={signOut}
        isConnected={isConnected}
      />
      <div className="gradient-circle gradient-circle-1"/>
      <div className={"content"}>
        <Outlet/>
      </div>
    </div>
  )
}


export default Layout
