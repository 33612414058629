import React, {ChangeEvent, ReactHTMLElement, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";

import currency from "../../assets/images/busd.svg"

import classNames from 'classnames/bind';
import styles from "./Home.module.css"
import ButtonSmallOutline from "../../components/Buttons/ButtonSmallOutline";
import Badge from "../../components/Badge/Badge";
import Button from "../../components/Buttons/Button";
import ButtonOutline from "../../components/Buttons/ButtonOutline";
import {TableColumns, TableData} from "../../models/Table";
import Table from "../../components/Table/Table";
import PlatformBackendV1, {Game as GameType} from "../../models/PlatformBackendV1";
import Input from "../../components/Input/Input";
import Textarea from "../../components/Textarea/Textarea";
import axios from "axios";
import {DEFAULT_BACKEND_URL} from "../../constants";
import Cookie from "../../models/Cookie";

type HomeProps = {
  isBackAuth: any
  name: any
  updateNickname: Function
  description: any
  updateDescription: Function
  avatar: any
  uploadAvatar: Function
  address: any
  balance: any
  profileBalance: any
  setBalanceModal: Function
  setProfileBalanceModal: Function
  setIsLoading: Function
  isConnected: boolean
}

function Home({
                isBackAuth,
                name,
                updateNickname,
                description,
                updateDescription,
                address,
                avatar,
                uploadAvatar,
                balance,
                profileBalance,
                isConnected,
                setBalanceModal,
                setProfileBalanceModal,
                setIsLoading,
}: HomeProps) {
  const navigate = useNavigate()
  const [nameEditing, setNameEditing] = useState(false);
  const [isNameValid, setIsNameValid] = useState(true);
  const [nameInputValue, setNameInputValue] = useState(name);
  const fileInput = useRef<HTMLInputElement>(null);
  const [descriptionEditing, setDescriptionEditing] = useState(false);
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);
  const [image, setImage] = useState('')
  const [descriptionInputValue, setDescriptionInputValue] = useState(description);

  const createGame = () => {
    navigate('/create-game')
  }
  const logout = () => {
    navigate('/')
  }

  const games = PlatformBackendV1.getGames()

  const cx = classNames.bind(styles);

  const tableColumns: TableColumns = [
    {
      title: "Игра",
      name: "image",
      width: 30,
      type: "image"
    },
    {
      title: "Время",
      name: "time",
      width: 50,
      type: "text"
    },
    {
      title: "Ставка",
      name: "rate",
      width: 50,
      type: "number"
    },
    {
      title: "Общий фонд",
      name: "fund",
      width: 80,
      type: "number"
    },
    {
      title: "Длительность",
      name: "duration",
      width: 90,
      type: "text"
    },
    {
      title: "Результат",
      name: "result",
      width: 75,
      type: "win-lose"
    }
  ]

  const tableDataToday: TableData = [
    {
      image: games.find(item => item.name === "Chess")?.image,
      time: "19:20",
      rate: "200",
      fund: "2000",
      duration: "21:32",
      result: "Lose",
    },
    {
      image: games.find(item => item.name === "Wordle")?.image,
      time: "18:11",
      rate: "150",
      fund: "2240",
      duration: "15:32",
      result: "Win",
    }
  ]

  const tableDataOld: TableData = [
    {
      image: games.find(item => item.name === "Chess")?.image,
      time: "15:10",
      rate: "500",
      fund: "5100",
      duration: "11:32",
      result: "Win",
    },
    {
      image: games.find(item => item.name === "Wordle")?.image,
      time: "19:12",
      rate: "500",
      fund: "2100",
      duration: "16:12",
      result: "Win",
    }
  ]

  const shortenAddress = (address: string | undefined) => {
    return address?.slice(0, 6) + "...." + address?.slice(-5)
  }

  const changeAvatar = async () => {
    fileInput.current?.click()
  };

  const handleChangeAvatar = async () => {
    const avatar = fileInput.current?.files?.item(0)
    if (avatar) {
      await handleUploadClick(avatar)
    }
  }

  const handleUploadClick = async (avatar: File) => {
    setIsLoading(true)
    try {
      if (!avatar) {
        return;
      }
      let formData = new FormData();
      formData.append("file", avatar);
      const res = await uploadAvatar(formData)
      console.log("res: ", res)
      if (res.data.code) {
        console.log(res.data.code)
        alert(res.data.code)
        setIsLoading(false)
        setNameInputValue(name)
        return
      }
    } catch (e) {
      console.error(e)
      setTimeout(handleUploadClick, 1000)
    }
  };

  const handleEditName = () => {
    setNameEditing(true)
  }

  const handleStopEditName = async () => {
    setIsLoading(true)
    try {
      const res = await updateNickname(nameInputValue)
      if (res.data.code) {
        alert(res.data.code)
        setNameInputValue(name)
        setIsLoading(false)
      }
      setNameEditing(false)
      Cookie.setNickname(nameInputValue)
    } catch (e) {
      console.error(e)
      setTimeout(handleUploadClick, 1000)
    }
    setIsLoading(false)
  }

  const handleCancelEditName = () => {
    setNameEditing(false)
  }

  const handleNameInputChange = (event: any) => {
    const value = event.target.value
    setNameInputValue(value)
    if (value.length > 0) {
      setIsNameValid(true)
    } else {
      setIsNameValid(false)
    }
  }

  const handleEditDescription = () => {
    setDescriptionEditing(true)
  }

  const handleStopEditDescription = async () => {
    setIsLoading(true)
    const res = await updateDescription(descriptionInputValue)
    if (res.data.address == address) {
      setDescriptionInputValue(res.data.description)
    }
    setDescriptionEditing(false)
    setIsLoading(false)
  }

  const handleCancelEditDescription = () => {
    setDescriptionEditing(false)
  }

  const handleDescriptionInputChange = (event: any) => {
    const value = event.target.value
    setDescriptionInputValue(value)
    if (value.length > 0) {
      setIsDescriptionValid(true)
    } else {
      setIsDescriptionValid(false)
    }
  }

  useEffect(() => {
    if (address){
      fileInput.current?.addEventListener('change', handleChangeAvatar)
    }
  }, [isBackAuth]);

  return (
    <div className={cx("home")}>
      <div className={cx("home__content")}>
        <div className={cx("home__profile-card")}>
          <div className={cx("home__profile__avatar")}>
            {
              !image ?
                <div className={cx("home__profile__avatar__circle")}>
                  <img src={avatar} alt="SpiderIcon"/>
                </div>
                :
                <img className={cx("home__profile__avatar__circle")} src={image} alt="SpiderIcon"/>
            }
            <div className={cx("home__profile__avatar__edit")}>
              <input type="file" className={cx("input-file")} ref={fileInput}/>
              <ButtonSmallOutline iconClasses={"bx bx-edit-alt"} onClick={changeAvatar}/>
            </div>
          </div>
          <div className={cx("home__profile__name")}>
            {
              !nameEditing &&
              <>
                <div className={cx("home__profile__name__edit")}>
                  <ButtonSmallOutline iconClasses={"bx bx-edit-alt"} onClick={handleEditName}/>
                </div>
                <span className={cx("small-title", "home__profile__name__text")}>
                  {nameInputValue == '' ? name : nameInputValue}
                </span>
              </>
            }
            {
              nameEditing &&
              <>
                <div className={cx("home__profile__name__edit__cancel")}>
                  <ButtonSmallOutline iconClasses={"bx bx-x"} onClick={handleCancelEditName}/>
                </div>
                <Input value={nameInputValue} placeholder={"Введите имя"} onChange={handleNameInputChange} maxLength={30}/>
                <div className={cx("home__profile__name__edit__check")}>
                  <ButtonSmallOutline iconClasses={"bx bx-check"} onClick={handleStopEditName} disabled={!isNameValid}/>
                </div>
              </>
            }
          </div>
        </div>
        <div className={cx("wallet")}>
          <Badge>
            Wallet:&nbsp;
            <span className={cx("wallet__number", "yellow")}>{shortenAddress(address)}</span>
          </Badge>
          <div className={cx("wallet__score")}>
            <Badge>
              Wallet:&nbsp;
              <span className={cx("wallet__number", "yellow")}>{balance}</span>
              <img src={currency} alt={"currency"}/>
            </Badge>
            <Badge>
              In-game Wallet:&nbsp;
              <span className={cx("wallet__number", "yellow")}>{profileBalance}</span>
              <img src={currency} alt={"currency"}/>
            </Badge>
          </div>
          {isConnected && (<>
            <Button iconClasses={"bx bx-plus"} width100={true} onClick={() => setBalanceModal({isOpen:true, type: "add-money"})}>
              <span>Add money</span>
            </Button>
            <div className={cx("button-withdraw")}>
              <ButtonOutline iconClasses={"bx bx-money-withdraw"} width100={true} onClick={() => setProfileBalanceModal({isOpen:true, type: "withdraw"})}>
                <span>Withdraw</span>
              </ButtonOutline>
            </div>
          </>)}
        </div>
        <div className={cx("profile-description")}>
          <h3 className={cx("small-title", "profile-description__title")}>Описание профиля</h3>
          <div className={cx("profile-description__content")}>
            {
              !descriptionEditing &&
              <>
                <div className={cx("profile-description__edit")}>
                  <ButtonSmallOutline iconClasses={"bx bx-edit-alt"} onClick={handleEditDescription}/>
                </div>
                <p className={"text"}>
                  {descriptionInputValue == '' ? description : descriptionInputValue}
                </p>
              </>
            }
            {
              descriptionEditing &&
              <>
                <Textarea value={descriptionInputValue} placeholder={"Введите описание"} onChange={handleDescriptionInputChange} maxLength={255}/>

                <div className={cx("home__profile__name__edit__check")}>
                  <ButtonSmallOutline iconClasses={"bx bx-check"} onClick={handleStopEditDescription} disabled={!isDescriptionValid}/>
                  <ButtonSmallOutline iconClasses={"bx bx-x"} onClick={handleCancelEditDescription}/>
                </div>
              </>
            }
          </div>
        </div>
      </div>
      <div className={cx("home__table")}>
        <h3 className={cx("small-title", "profile-table__title")}>Сегодня</h3>
        <Table tableColumns={tableColumns} tableData={tableDataToday}/>
      </div>
      <div className={cx("home__table")}>
        <h3 className={cx("small-title", "profile-table__title")}>26.09.2022</h3>
        <Table tableColumns={tableColumns} tableData={tableDataOld}/>
      </div>
    </div>




    // <Form>
    //   <h3 className="mt-3">{locale.t('Home')}</h3>
    //   <p>{locale.t('Welcome')}, {user.nickname}. {locale.t('Your balance')}: ${user.balance}</p>
    //   <FindGame/>
    //   <div className="d-grid gap-2">
    //     <Button variant="primary" size="sm" role={"button"} onClick={createGame}>{locale.t('Create game')}</Button>
    //     <Button variant="secondary" size="sm" role={"button"} onClick={logout}>{locale.t('Logout')}</Button>
    //   </div>
    // </Form>
  )
}

export default Home
