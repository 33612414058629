import classNames from 'classnames/bind';
import styles from "./Buttons.module.css"
import {ReactNode} from "react";

type ButtonSmallProps = {
    children: ReactNode
    iconClasses: string,
    onClick: any
}

function ButtonSmall({children, iconClasses, onClick}:ButtonSmallProps) {

    const cx = classNames.bind(styles);

    return (
        <button className={cx("button", "button-small")} onClick={onClick}>
            {children && <span className={cx("button-small__text")}>{children}</span>}
            <i className={cx(iconClasses, "button-small__icon")}></i>
        </button>
    )
}


export default ButtonSmall