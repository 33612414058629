import classNames from 'classnames/bind';
import styles from "./Select.module.css"
import Select, {ActionMeta} from 'react-select'

type SelectProps = {
  options: Array<any>,
  selectedOption?: any,
  value?: any
  onChange?: ((newValue: any, actionMeta: ActionMeta<any>) => void) | undefined
}

function CustomSelect({options, onChange, value}: SelectProps) {
  const cx = classNames.bind(styles);

  const customStyles = {
    control: (provided: any, state: any) => {
      return {
        ...provided,
        width: "100%",
        background: "var(--dark)",
        border: "1px solid var(--purple)!important",
        boxShadow: "none",
        borderRadius: 5,
        height: 50,
        paddingRight: 15,
        cursor: "pointer",
      }
    },
    valueContainer: (provided: any) => ({
      ...provided,
      paddingLeft: 20,
    }),
    indicatorSeparator: () => ({}),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "white!important",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "white",
      fontWeight: "bold"
    }),
    input: (provided: any) => ({
      ...provided,
      color: "white",
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: "0 0 5px 5px",
      background: "var(--dark)",
      marginTop: "-5px",
      border: "1px solid var(--purple)",
      borderTop: "0"
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: 0,
      borderRadius: 5,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      padding: "10px 20px 10px 20px",
      borderRadius: 5,
      color: "white!important",
      background: "var(--dark)",
      cursor: "pointer",
      fontWeight: "bold"
    }),
  }

  return (
    <div className={cx("select-wrap")}>
      <Select options={options} value={value} styles={customStyles} onChange={onChange} hideSelectedOptions={true}/>
    </div>
  )
}


export default CustomSelect