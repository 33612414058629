import classNames from 'classnames/bind';
import styles from "./Select.module.css"

export type Option = {
  name: string,
  value: string | number | undefined
}

type SelectProps = {
  options: Array<Option>,
  selectedOption?: Option
  titleOption?: Option
  setSelectedOption: Function
}

function Select({options, selectedOption, titleOption, setSelectedOption}: SelectProps) {
  const cx = classNames.bind(styles);


  const handleSelect = (e: any) => {
    const newSelectedValue = options.find(item => item.value === e.target.value)
    setSelectedOption(newSelectedValue)
  }

  return (
    <div className={cx("select-wrap")} >
      <select className={cx("select")} value={selectedOption?.value} onChange={handleSelect}>
        {titleOption &&
          <option value={titleOption.value} selected disabled hidden>{titleOption.name}</option>}
        {options.map((item, index) =>
          <option value={item.value} key={index}>{item.name}</option>
        )}
      </select>
    </div>
  )
}


export default Select