import { Game as GameType } from "../../models/PlatformBackendV1"
import classNames from 'classnames/bind';

import styles from "./GameList.module.css"
import Button from "../../components/Buttons/Button";
import Tooltip from "../../components/Tooltip/Tooltip";

function Game(props: { game: GameType ,playersCount: number}) {
  const cx = classNames.bind(styles);

  return (
    <div className={cx("game-wrap")}>
      <div className={cx("game", {"blurred": !props.game.isActive})} >
        <div className={cx("tooltip-wrap")}>
          <Tooltip position={"top left"}>
            {props.game.description}
          </Tooltip>
        </div>
        <div className={cx("game__column")}>
          {props.game.image && <img src={props.game.image} alt="Image" className={cx("game__image")}/>}
          <span className={"small-title"}>{props.game.name}</span>
        </div>
        <div className={cx("game__column")}>
          <Button onClick={() => { window.location.href = props.game.link }} iconClasses={"bx bx-play-circle"}>
            Play
          </Button>
          <span className={cx("game__players-count")}>
          Игроков онлайн: <span className={"pink"}>{props.playersCount}</span>
        </span>
        </div>
      </div>
      {!props.game.isActive &&
        <div className={cx("game-wrap__content")}>
          <span className={"title"}>{props.game.name}</span>
          <span className={cx("game-wrap__content__soon")}>Скоро</span>
        </div>
      }
    </div>
  )
}

export default Game