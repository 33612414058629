import {Link} from "react-router-dom"
import Select from "../../components/Select/Select";
import {Option} from "../../components/Select/Select"
import Badge from "../../components/Badge/Badge";
import Button from "../../components/Buttons/Button";
import classNames from 'classnames/bind';
import styles from "./Main.module.css"

import currency from "../../assets/images/busd.svg"


type MainProps = {
  langs: Array<Option>,
  selectedLang: Option,
  setSelectedLang: Function,
  players: number
  rooms: number
  profileBalance: number
  isBackAuth: boolean
  isConnected: boolean
}

function Main({langs, isConnected, rooms, players,selectedLang, setSelectedLang, profileBalance, isBackAuth}: MainProps) {
  const cx = classNames.bind(styles);

  return (
    <>
      <div className={cx("main__top")}>
        <div className={cx("main__stats")}>
          <div className={cx("stat")}>
            <Badge>
              Комнат: <span className={cx("players-count", "pink")}>{rooms}</span>
            </Badge>
          </div>
          <div className={cx("stat")}>
            <Badge>
              Игроков: <span className={cx("players-count", "pink")}>{players}</span>
            </Badge>
          </div>
        </div>
        <Select options={langs} selectedOption={selectedLang} setSelectedOption={setSelectedLang}/>
      </div>
      <div className={cx("main__content")}>
        <h1 className={cx("main__title")}>
          Играйте и соревнуйтесь в интеллектуальные игры со ставками в <span className={"yellow"}>BUSD</span> против
          других игроков
        </h1>
        {isBackAuth && isConnected && (
            <Link to={'/game-list'} className={cx("link")}>
              <Button>
                Играть!
              </Button>
            </Link>
        )}
        <div className={cx("main__currency__wrap")}>
          <Badge>
            <img src={currency} alt={"currency"}/>
            <span className={cx("main__currency", "yellow")}>BUSD</span>
            <span className={cx("main__currency-money")}>: {profileBalance}</span>
          </Badge>
        </div>
        <p className={cx("main__text", "text")}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          <br/><br/>
          Morbi at cursus sed viverra sociis tempus. Id nibh risus cursus ut turpis blandit.
          <br/><br/>
          Luctus egestas porta enim eu integer turpis amet. Facilisis malesuada dolor eget vestibulum, adipiscing
          aliquam.
        </p>
      </div>
    </>
  )
}

export default Main
