import classNames from 'classnames/bind';
import styles from "./Input.module.css"

type InputProps = {
  value?: string,
  placeholder?: string,
  type?: string,
  onChange?: any
  isBig?: boolean
  maxLength?: number
}

function Input({value, placeholder, type = "text", onChange, isBig = false, maxLength = 255}: InputProps) {
  const cx = classNames.bind(styles);

  return (
    <div className={cx("input-wrap")}>
      <input type={type} className={cx({"big": isBig}, "input")} value={value} onChange={onChange}
             placeholder={placeholder} maxLength={maxLength}/>
    </div>
  )
}


export default Input