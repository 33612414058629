import {Container, Nav, Navbar} from "react-bootstrap";
import ButtonSmallOutline from "../Buttons/ButtonSmallOutline";
import classNames from 'classnames/bind';

import styles from "./Header.module.css"
import menu from "../../assets/images/menu.svg"
import logo from "../../assets/images/logo.svg"
import ButtonSmall from "../Buttons/ButtonSmall";
import {Link, useLocation} from "react-router-dom";
import { ConnectKitButton } from 'connectkit';
import {useDisconnect} from "wagmi";


type HeaderProps = {
  isBackAuth: any,
  signInWithEthereum: Function,
  setBalanceModal: Function,
  signOut: Function,
  isConnected: Boolean,
}

function Header({
                  isBackAuth,
                  signInWithEthereum,
                  setBalanceModal,
                  signOut,
                  isConnected,
                }: HeaderProps
) {
  const location = useLocation();
  const cx = classNames.bind(styles);
  const { disconnect } = useDisconnect()

  return (
    <div className={cx("header")}>
      <Container>
        <Navbar expand='md'>
          <Link to="/" className={cx("logo")} onClick={() => console.log("logo-click")}>
            <button className={cx("logo-btn")} onClick={() => console.log("logo-btn-click")}>
              <img src={logo} alt="Logo"/>
            </button>
          </Link>
          <Nav className="grid" activeKey="/slink">
              {isConnected && isBackAuth && (
                  <ConnectKitButton.Custom>
                      {() => {
                          return (
                              <div className={cx("header__btns")}>
                                  <ButtonSmallOutline iconClasses={"bx bx-log-in"} onClick={() => {
                                      disconnect()
                                      signOut()
                                  }}>
                                      {" "}
                                  </ButtonSmallOutline>
                                  {location.pathname !== "/home" &&
                                      <Nav.Item className={cx("plus-btn")}>
                                          <ButtonSmall iconClasses={"bx bx-plus"}
                                                       onClick={() => setBalanceModal({isOpen: true, type: "add-money"})}>
                                              Add money
                                          </ButtonSmall>
                                      </Nav.Item>
                                  }
                                  {location.pathname !== "/home" &&
                                      <Nav.Item className={cx("profile-btn")}>
                                          <Link to={'/home'} className={cx("link")}>
                                              <ButtonSmallOutline iconClasses={"bx bx-user"}/>
                                          </Link>
                                      </Nav.Item>
                                  }
                              </div>
                          )
                      }}
                  </ConnectKitButton.Custom>
              )}
              {!isConnected && (
                  <ConnectKitButton.Custom>
                      {({ show }) => {
                          return (
                              <>
                                  <ButtonSmallOutline iconClasses={"bx bx-log-in"} onClick={show}>
                                      Connect
                                  </ButtonSmallOutline>
                              </>
                          )
                      }}
                  </ConnectKitButton.Custom>
              )}
              {!isBackAuth && isConnected && (
                  <ConnectKitButton.Custom>
                      {() => {
                          return (
                              <>
                                  <ButtonSmallOutline iconClasses={"bx bx-log-in"} onClick={signInWithEthereum}>
                                      Sign In
                                  </ButtonSmallOutline>
                              </>
                          )
                      }}
                  </ConnectKitButton.Custom>
              )}
          </Nav>
      </Navbar>
    </Container>
</div>
)
}

export default Header
