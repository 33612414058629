import {useEffect, useState} from 'react'
import {Container,} from 'react-bootstrap'
import Holder from 'holderjs'
import PlatformBackendV1, {Game as GameType} from '../../models/PlatformBackendV1'
import Game from './Game'

import classNames from 'classnames/bind';

import styles from "./GameList.module.css"

type GameListProps = {
    playersCount: number
}

export const GameList = ({
                            playersCount,
                         }: GameListProps): JSX.Element => {
  const [games, setGames] = useState<GameType[]>([])

  useEffect(() => {
    Holder.run()
    setGames(PlatformBackendV1.getGames())
  }, [])

  const cx = classNames.bind(styles);

  return (
    <>
      <h2 className={cx("game-list__title","title")}>Games</h2>
      <div className={cx("game-list__games")}>
        {games.map((game, index) => {
          return <Game game={game} key={index} playersCount={playersCount} />
        })}
      </div>
    </>
  )
}

export default GameList
