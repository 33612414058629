import classNames from 'classnames/bind';
import styles from "./Buttons.module.css"
import {ReactNode} from "react";

type ButtonSmallOutlineProps = {
    children?: ReactNode,
    iconClasses: string,
    onClick?: any,
    disabled?: boolean,
}

function ButtonSmallOutline({children, iconClasses, onClick, disabled}:ButtonSmallOutlineProps) {

    const cx = classNames.bind(styles);

    return (
        <button className={cx("button", "button-outline", "button-small", {"no-text": !children})} onClick={onClick} disabled={disabled}>
            <i className={cx(iconClasses, "button-small__icon")}></i>
            {children && <span className={cx("button-small__text")}>{children}</span>}
        </button>
    )
}


export default ButtonSmallOutline
