import CustomSelect from "../CustomSelect/Select";
import Input from "../Input/Input";
import {getTrackBackground, Range} from 'react-range';
import {useEffect, useState} from "react";

import classNames from 'classnames/bind';
import styles from "./AddMoney.module.css"
import busdIcon from "../../assets/images/busd.svg";
import usdtIcon from "../../assets/images/usdt.svg";
import ButtonOutline from "../Buttons/ButtonOutline";
import Button from "../Buttons/Button";

type AddMoneyProps = {
  isOpen: boolean,
  onClose: Function,
  type: string,
  balance: string,
  deposit: Function
}

function AddMoney({isOpen = false, onClose, type, balance, deposit}: AddMoneyProps) {
  const cx = classNames.bind(styles);

  const options = [
    {
      value: 'BUSD',
      label:
        <div style={{display: "flex", alignItems: "center"}}>
          <img src={busdIcon} width={25} height={25}/>
          <span style={{marginLeft: 10, fontWeight: "bold", color: "white"}}>BUSD</span>
        </div>
    },
    // {
    //   value: 'USDT',
    //   label:
    //     <div style={{display: "flex", alignItems: "center"}}>
    //       <img src={usdtIcon} width={25} height={25}/>
    //       <span style={{marginLeft: 10, fontWeight: "bold", color: "white"}}>USDT</span>
    //     </div>
    // },
  ];

  const [currency, setCurrency] = useState(options[0]);
  const [availableMoney, setAvailableMoney] = useState("0.0");
  const [moneyInputValue, setMoneyInputValue] = useState("0.0");

  useEffect(() => {
    if (balance != '0') {
      setAvailableMoney(balance)
    }
  })

  const onClick = (amount: string) => () => {
    deposit(amount)
    handleCloseModal()
  }

  const handleSelectChange = (value: any) => {
    setCurrency(value)
  }

  const handleInputChange = (event: any) => {
    const value = event.target.value
    setMoneyInputValue(value)
  }

  const handleCloseModal = () => {
    onClose()
  }

  const rangeStep = 0.1
  const rangeValues = parseFloat(availableMoney) > 0 ? [Number(moneyInputValue)] : [0]
  //Hack to fix range component
  const rangeMax = parseFloat(availableMoney) > 0 ? parseFloat(availableMoney) : rangeStep / 10000

  return (
    <div className={cx({"isOpen": isOpen}, "add-money-wrap")}>
      <div className={cx("add-money")}>
        <div className={cx("add-money__close")} onClick={handleCloseModal}>
          <i className="bx bx-x"></i>
        </div>
        <h3 className={cx("title", "add-money__title")}>
          {
            type === "add-money" && "Add money"
          }
          {
            type === "withdraw" && "Withdraw"
          }
        </h3>
        <p className={cx("text", "add-money__text")}>
          {
            type === "add-money" && "Select the currency you want to deposit to your balance on the platform."
          }
          {
            type === "withdraw" && "Select the currency you want to withdraw to your balance on your wallet."
          }
        </p>
        <CustomSelect options={options} value={currency} onChange={handleSelectChange}/>
        <div className={cx("add-money__input-group")}>
          <span className={cx("add-money__input__available")}>
            <span className={cx("add-money__input__available__text")}>Available: </span>
            <span className={cx("add-money__input__available__value")}>
              {availableMoney} {currency.value}
            </span>
          </span>
          <div className={cx("add-money__input-wrap")}>
            <Input value={moneyInputValue} onChange={handleInputChange} isBig={true} placeholder={"0.0"}/>
            <div className={cx("add-money__input__currency")}>
              {currency.label}
            </div>
          </div>
          <div className={cx("add-money__range-wrap")}>
            <Range
              values={rangeValues}
              step={rangeStep}
              min={0.0}
              max={rangeMax}
              onChange={(values) => setMoneyInputValue(String(values))}
              renderTrack={({props, children}) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: "36px",
                    display: "flex",
                    width: "100%"
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: "8px",
                      width: "100%",
                      borderRadius: "4px",
                      background: getTrackBackground({
                        values: rangeValues,
                        colors: ["var(--purple)", "rgba(255,255,255,0.2)"],
                        min: 0.0,
                        max: rangeMax
                      }),
                      alignSelf: "center"
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({props, isDragged}) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "25px",
                    width: "25px",
                    borderRadius: "50%",
                    backgroundColor: "var(--purple)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "none",
                    outline: "none",
                  }}
                >
                  <div
                    style={{
                      height: "14px",
                      width: "3px",
                      borderRadius: '4px',
                      backgroundColor: "white"
                    }}
                  />
                </div>
              )}
            />
          </div>
          <div className={cx("btns")}>
            <ButtonOutline width100={true} onClick={handleCloseModal}>
              <span>Cancel</span>
            </ButtonOutline>
            {
              type === "add-money" &&
              <Button width100={true} onClick={onClick(moneyInputValue)}>
                <span>Deposit</span>
              </Button>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddMoney
