import wordleImage from "../assets/images/games/game2.png";
import chessImage from "../assets/images/games/game1.png";
import {DEFAULT_BACKEND_URL, DEFAULT_BROWSER_URL} from "../constants";

export type Game = {
  name: string,
  description: string,
  isActive: boolean,
  image?: string,
  link: string,
}

export default class PlatformBackendV1 {
  public static getGames(): Game[] {
    return [
      {
        name: "Wordle",
        description: 'The first "Wordle" multiplayer with decentralized cryptocurrency bets between players!',
        isActive: true,
        image: wordleImage,
        link: (String(DEFAULT_BROWSER_URL) == 'https://spiderx.io/' ? "https://wordle.spiderx.io" : "https://localhost:3001/")
      },
      {
        name: "Chess",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        isActive: false,
        image: chessImage,
        link: (String(DEFAULT_BROWSER_URL) == 'https://spiderx.io/' ? "https://chess.spiderx.io" : "https://localhost:3002/")
      },
      {
        name: "Checkers",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        isActive: false,
        link: "https://checkers.spiderx.io",
      },
      {
        name: "Pinocle",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        isActive: false,
        link: "https://pinocle.spiderx.io",
      }
    ]
  }
}
