import classNames from 'classnames/bind';
import styles from "./Buttons.module.css"
import {ReactNode} from "react";

type ButtonProps = {
    children: ReactNode
    iconClasses?: string,
    width100?: boolean,
    onClick?: any
}

function Button({children, iconClasses, width100, onClick}:ButtonProps) {

    const cx = classNames.bind(styles);

    return (
        <button className={cx({"width100": width100}, "button", "button-big", "button-outline")} onClick={onClick}>
            <span>{children}</span>
            <i className={cx(iconClasses, "button-big__icon")}></i>
        </button>
    )
}


export default Button