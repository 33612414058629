import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './index.css';
import spiderIcon from './assets/images/spider.svg'
import App from './App';
import {WagmiConfig, createClient, Chain, configureChains} from 'wagmi';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { ConnectKitProvider, getDefaultClient } from "connectkit";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

const bnbSmartChain: Chain = {
  id: 97,
  name: 'BNB Smart Chain',
  network: 'BSC',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: 'https://rpc.ankr.com/bsc_testnet_chapel',
  },
  blockExplorers: {
    default: { name: 'BscScan', url: 'https://testnet.bscscan.com' },
  },
  testnet: true,
};
const { provider, chains } = configureChains(
  [bnbSmartChain],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id !== bnbSmartChain.id) {
            console.log("NULL JSON RPC")
            return null;
        }
        return { http: chain.rpcUrls.default };
      },
    }),
  ],
);

const client = createClient(
  getDefaultClient({
    appIcon: spiderIcon,
    appName: "Your App Name",
    chains,
    provider,
  }),
);

const wcClient = createClient({
    autoConnect: true,
    connectors: [
        new WalletConnectConnector({
            chains: chains,
            options: {
                qrcode: false,
            },
        }),
    ],
    provider,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <>
        { window.ethereum ? (
            <WagmiConfig client={client}>
                <ConnectKitProvider theme="midnight">
                    <App />
                </ConnectKitProvider>
            </WagmiConfig>
        ) : (
            <WagmiConfig client={wcClient}>
                <ConnectKitProvider theme="midnight">
                    <App />
                </ConnectKitProvider>
            </WagmiConfig>
        ) }
    </>
);

reportWebVitals();
