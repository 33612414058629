import classNames from 'classnames/bind';
import styles from "./Loader.module.css"
import {useEffect, useState} from "react";


function Loader() {
  const cx = classNames.bind(styles);
  const [dots, setDots] = useState(".");

  let count = 0

  const dotsUpdate = () => {
    let str = ""
    count++
    for (let i = 0; i<count; i++) {
      str+="."
    }
    setDots(str)
    if (count >= 3) {
      count = 0
    }
  }


  useEffect(() => {
    const myInterval = setInterval(dotsUpdate, 500);
    return () => {
      clearInterval(myInterval);
    };
  }, [])


  return (
    <div className={cx("loader-wrap")}>
      <span className={cx("loader-text")}>Загрузка{dots}</span>
    </div>
  )
}

export default Loader