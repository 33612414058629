import classNames from 'classnames/bind';
import styles from "./Tooltip.module.css"
import {ReactNode, useEffect, useRef, useState} from "react";


type TooltipProps = {
  children: ReactNode,
  position?: string
}

function Tooltip({children, position = "top right"}: TooltipProps) {
  const cx = classNames.bind(styles);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<any>(null);

  const handleClickOutside = (event:any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);



  const openTooltip = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <span className={cx("tooltip")} ref={ref}>
        <span className={cx("tooltip__block")} onClick={openTooltip}>
          <i className={"bx bx-question-mark"}></i>
        </span>
        <span className={cx({"isOpen": isOpen}, "tooltip__content", ...position?.split(" "))}>
        {children}
        </span>
      </span>
    </>
  )
}


export default Tooltip