import classNames from 'classnames/bind';
import styles from "./Textarea.module.css"

type TextareaProps = {
  value?: string,
  placeholder?: string,
  onChange?: any
  maxLength?: number
}

function Textarea({value, placeholder, onChange, maxLength = 255}: TextareaProps) {
  const cx = classNames.bind(styles);

  return (
    <div className={cx("textarea-wrap")}>
      <textarea className={cx("textarea")} value={value} onChange={onChange} placeholder={placeholder} maxLength={maxLength}></textarea>
    </div>
  )
}


export default Textarea