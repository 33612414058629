export const BUSD = {
  address: "0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7",
  abi: [
    {
      "inputs":[

      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"constructor"
    },
    {
      "anonymous":false,
      "inputs":[
        {
          "indexed":true,
          "internalType":"address",
          "name":"owner",
          "type":"address"
        },
        {
          "indexed":true,
          "internalType":"address",
          "name":"spender",
          "type":"address"
        },
        {
          "indexed":false,
          "internalType":"uint256",
          "name":"value",
          "type":"uint256"
        }
      ],
      "name":"Approval",
      "type":"event"
    },
    {
      "anonymous":false,
      "inputs":[
        {
          "indexed":true,
          "internalType":"address",
          "name":"previousOwner",
          "type":"address"
        },
        {
          "indexed":true,
          "internalType":"address",
          "name":"newOwner",
          "type":"address"
        }
      ],
      "name":"OwnershipTransferred",
      "type":"event"
    },
    {
      "anonymous":false,
      "inputs":[
        {
          "indexed":true,
          "internalType":"address",
          "name":"from",
          "type":"address"
        },
        {
          "indexed":true,
          "internalType":"address",
          "name":"to",
          "type":"address"
        },
        {
          "indexed":false,
          "internalType":"uint256",
          "name":"value",
          "type":"uint256"
        }
      ],
      "name":"Transfer",
      "type":"event"
    },
    {
      "constant":true,
      "inputs":[

      ],
      "name":"_decimals",
      "outputs":[
        {
          "internalType":"uint8",
          "name":"",
          "type":"uint8"
        }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
    },
    {
      "constant":true,
      "inputs":[

      ],
      "name":"_name",
      "outputs":[
        {
          "internalType":"string",
          "name":"",
          "type":"string"
        }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
    },
    {
      "constant":true,
      "inputs":[

      ],
      "name":"_symbol",
      "outputs":[
        {
          "internalType":"string",
          "name":"",
          "type":"string"
        }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
    },
    {
      "constant":true,
      "inputs":[
        {
          "internalType":"address",
          "name":"owner",
          "type":"address"
        },
        {
          "internalType":"address",
          "name":"spender",
          "type":"address"
        }
      ],
      "name":"allowance",
      "outputs":[
        {
          "internalType":"uint256",
          "name":"",
          "type":"uint256"
        }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
    },
    {
      "constant":false,
      "inputs":[
        {
          "internalType":"address",
          "name":"spender",
          "type":"address"
        },
        {
          "internalType":"uint256",
          "name":"amount",
          "type":"uint256"
        }
      ],
      "name":"approve",
      "outputs":[
        {
          "internalType":"bool",
          "name":"",
          "type":"bool"
        }
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
    },
    {
      "constant":true,
      "inputs":[
        {
          "internalType":"address",
          "name":"account",
          "type":"address"
        }
      ],
      "name":"balanceOf",
      "outputs":[
        {
          "internalType":"uint256",
          "name":"",
          "type":"uint256"
        }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
    },
    {
      "constant":false,
      "inputs":[
        {
          "internalType":"uint256",
          "name":"amount",
          "type":"uint256"
        }
      ],
      "name":"burn",
      "outputs":[
        {
          "internalType":"bool",
          "name":"",
          "type":"bool"
        }
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
    },
    {
      "constant":true,
      "inputs":[

      ],
      "name":"decimals",
      "outputs":[
        {
          "internalType":"uint8",
          "name":"",
          "type":"uint8"
        }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
    },
    {
      "constant":false,
      "inputs":[
        {
          "internalType":"address",
          "name":"spender",
          "type":"address"
        },
        {
          "internalType":"uint256",
          "name":"subtractedValue",
          "type":"uint256"
        }
      ],
      "name":"decreaseAllowance",
      "outputs":[
        {
          "internalType":"bool",
          "name":"",
          "type":"bool"
        }
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
    },
    {
      "constant":true,
      "inputs":[

      ],
      "name":"getOwner",
      "outputs":[
        {
          "internalType":"address",
          "name":"",
          "type":"address"
        }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
    },
    {
      "constant":false,
      "inputs":[
        {
          "internalType":"address",
          "name":"spender",
          "type":"address"
        },
        {
          "internalType":"uint256",
          "name":"addedValue",
          "type":"uint256"
        }
      ],
      "name":"increaseAllowance",
      "outputs":[
        {
          "internalType":"bool",
          "name":"",
          "type":"bool"
        }
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
    },
    {
      "constant":false,
      "inputs":[
        {
          "internalType":"uint256",
          "name":"amount",
          "type":"uint256"
        }
      ],
      "name":"mint",
      "outputs":[
        {
          "internalType":"bool",
          "name":"",
          "type":"bool"
        }
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
    },
    {
      "constant":true,
      "inputs":[

      ],
      "name":"name",
      "outputs":[
        {
          "internalType":"string",
          "name":"",
          "type":"string"
        }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
    },
    {
      "constant":true,
      "inputs":[

      ],
      "name":"owner",
      "outputs":[
        {
          "internalType":"address",
          "name":"",
          "type":"address"
        }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
    },
    {
      "constant":false,
      "inputs":[

      ],
      "name":"renounceOwnership",
      "outputs":[

      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
    },
    {
      "constant":true,
      "inputs":[

      ],
      "name":"symbol",
      "outputs":[
        {
          "internalType":"string",
          "name":"",
          "type":"string"
        }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
    },
    {
      "constant":true,
      "inputs":[

      ],
      "name":"totalSupply",
      "outputs":[
        {
          "internalType":"uint256",
          "name":"",
          "type":"uint256"
        }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
    },
    {
      "constant":false,
      "inputs":[
        {
          "internalType":"address",
          "name":"recipient",
          "type":"address"
        },
        {
          "internalType":"uint256",
          "name":"amount",
          "type":"uint256"
        }
      ],
      "name":"transfer",
      "outputs":[
        {
          "internalType":"bool",
          "name":"",
          "type":"bool"
        }
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
    },
    {
      "constant":false,
      "inputs":[
        {
          "internalType":"address",
          "name":"sender",
          "type":"address"
        },
        {
          "internalType":"address",
          "name":"recipient",
          "type":"address"
        },
        {
          "internalType":"uint256",
          "name":"amount",
          "type":"uint256"
        }
      ],
      "name":"transferFrom",
      "outputs":[
        {
          "internalType":"bool",
          "name":"",
          "type":"bool"
        }
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
    },
    {
      "constant":false,
      "inputs":[
        {
          "internalType":"address",
          "name":"newOwner",
          "type":"address"
        }
      ],
      "name":"transferOwnership",
      "outputs":[

      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
    }
  ],
};
