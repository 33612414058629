import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import {
  BrowserRouter,
  Route, Routes,
} from "react-router-dom";
import {Container} from 'react-bootstrap';
import Login from "./routes/Login";
import Home from "./routes/Home/Home";
import Main from "./routes/Main/Main";
import GameList from "./routes/GameList/GameList";
import React, {useState, useEffect} from "react";
import Layout from "./components/Layout";
import AddMoney from "./components/AddMoney/AddMoney";
import WithdrawMoney from "./components/WithdrawMoney/WithdrawMoney";
import {
  useAccount,
  useBalance, useContractEvent,
  useContractRead,
  useContractWrite, useNetwork,
  usePrepareContractWrite,
  useSigner,
  useWaitForTransaction
} from "wagmi";
import {BUSD, DEFAULT_BACKEND_URL, SPIDERX} from "./constants";
import {ethers} from "ethers";
import axios from "axios";
import spiderIcon from "./assets/images/spider.svg"
import {SiweMessage} from "siwe";
import Loader from "./components/Loader/Loader";
import Cookie from "./models/Cookie";
import Game from "./routes/GameList/Game";

export const App = (): JSX.Element => {
  const [langs, setLangs] = useState([
    {
      name: "Rus",
      value: "Ru"
    },
    {
      name: "Eng",
      value: "En"
    },
  ])
  const [selectedLang, setSelectedLang] = useState(langs[0])
  const [balanceModal, setBalanceModal] = useState({isOpen: false, type: "add-money"})
  const [profileBalanceModal, setProfileBalanceModal] = useState({isOpen: false, type: "withdraw"})
  const [userAddress, setUserAddress] = useState('')

  const [playersCount, setPlayersCount] = useState(0)
  const [roomsCount, setRoomsCount] = useState(0)
  const { chain } = useNetwork()
  const { address, isDisconnected } = useAccount()
  const { data: signer } = useSigner()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [avatar, setAvatar] = useState(spiderIcon)
  const [ balance, setBalance ] = useState('0')
  const [ profileBalance, setProfileBalance ] = useState('0')
  const [isBackAuth, setIsBackAuth] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useBalance({
    addressOrName: userAddress,
    token: BUSD.address,
    watch: true,
    onSuccess(data) {
      const newBalance = parseFloat(data.formatted).toFixed(2)
      if (newBalance != balance) {
        setBalance(newBalance)
        axios.patch<any>(`${DEFAULT_BACKEND_URL}/users/${userAddress}/updateBalance`, {
          'wallet_balance': parseInt(newBalance),
          'platform_balance': parseInt(profileBalance)
        }).then(res => {
          console.log(res.data)
        })
      }
    },
  })
  useContractRead({
    contractInterface: SPIDERX.abi,
    addressOrName: SPIDERX.address,
    functionName: 'balances',
    args: [userAddress],
    watch: true,
    onSuccess(data) {
      if (data) {
        const newBalance = ethers.utils.formatEther(data.toString())
        if (newBalance != profileBalance) {
          setProfileBalance(newBalance)
          Cookie.setBalance(parseFloat(newBalance))
          axios.patch<any>(`${DEFAULT_BACKEND_URL}/users/${userAddress}/updateBalance`, {
            'wallet_balance': parseInt(balance),
            'platform_balance': parseInt(newBalance)
          }).then(res => {
            console.log(res.data)
          })
        }
      }
    },
  })

  const { config: transferConfig } = usePrepareContractWrite({
    addressOrName: BUSD.address,
    contractInterface: BUSD.abi,
    functionName: 'transfer',
    args: [SPIDERX.address, ethers.utils.parseEther('1')]
  })

  const { data: transferData, writeAsync: transfer = async () => {} } = useContractWrite({
    ...transferConfig,
    onSuccess(data) {
      console.log('Success', data)
    },
  })
  const transferBusd = async (amount:string) => {
    const tx = await transfer({recklesslySetUnpreparedArgs: [SPIDERX.address, ethers.utils.parseEther(amount)]})
    const res = await axios.post<any>(`${DEFAULT_BACKEND_URL}/transactions/deposit`, tx)
    setIsLoading(true)
    if (res.data.reason) setIsLoading(false)
    console.log(res)
  }

  const { config: withdrawConfig } = usePrepareContractWrite({
    addressOrName: SPIDERX.address,
    contractInterface: SPIDERX.abi,
    functionName: 'withdraw',
    args: [ethers.utils.parseEther('1')]
  })

  const { data: withdrawData, writeAsync: withdraw = async () => {} } = useContractWrite(withdrawConfig)
  const withdrawBusd = async (amount:string) => {
    await withdraw(
      {recklesslySetUnpreparedArgs: [ethers.utils.parseEther(amount)]})
    setIsLoading(true)
  }

  useWaitForTransaction({
    hash: transferData?.hash,
    confirmations: 1,
    timeout: 30_000, // 30 seconds
    onSuccess(data) {
      console.log('Deposit', data)
      setIsLoading(false)
    },
    onError(data) {
      console.error('Error', data)
      alert(data)
      setIsLoading(false)
    },
  })

  useWaitForTransaction({
    hash: withdrawData?.hash,
    confirmations: 1,
    timeout: 30_000, // 30 seconds
    onSuccess(data) {
      console.log('Withdraw', data)
      setIsLoading(false)
    },
    onError(data) {
      console.error('Error', data)
      alert(data)
      setIsLoading(false)
    },
  })

  useContractEvent({
    addressOrName: SPIDERX.address,
    contractInterface: SPIDERX.abi,
    eventName: 'NicknameChange',
    listener(userAddress) {
      console.log(userAddress, ' changed nickname')
      setTimeout(updateProfile, 3000)
      setIsLoading(false)
    },
  })

  useContractEvent({
    addressOrName: SPIDERX.address,
    contractInterface: SPIDERX.abi,
    eventName: 'AvatarChange',
    listener(userAddress) {
      console.log(userAddress, ' changed avatar')
      setTimeout(updateProfile, 3000)
      setIsLoading(false)
    },
  })

  useContractEvent({
    addressOrName: SPIDERX.address,
    contractInterface: SPIDERX.abi,
    eventName: 'CreateRoom',
    listener(data) {
      console.log(data)
      updateRoomsStats().then()
    },
  })

  useContractEvent({
    addressOrName: SPIDERX.address,
    contractInterface: SPIDERX.abi,
    eventName: 'CancellGame',
    listener(data) {
      console.log(data)
      updateRoomsStats().then()
    },
  })

  useContractEvent({
    addressOrName: SPIDERX.address,
    contractInterface: SPIDERX.abi,
    eventName: 'Payment',
    listener(data) {
      console.log(data)
      updateRoomsStats().then()
    },
  })

  const updateNickname = async (nameInputValue: string) => {
    return await axios.patch<any>(`${DEFAULT_BACKEND_URL}/users/${userAddress}/updateNickname`, {name: nameInputValue})
  }

  const updateDescription = async (descriptionInputValue: string) => {
    return await axios.patch<any>(`${DEFAULT_BACKEND_URL}/users/${userAddress}/updateDescription`, {description: descriptionInputValue})
  }

  const uploadAvatar = async (formData: object) => {
    return await axios.post<any>(`${DEFAULT_BACKEND_URL}/users/${userAddress}/uploadAvatar`, formData)
  }

  const createSiweMessage = async function (address: string | undefined, statement: any) {
      const res = await fetch(`${DEFAULT_BACKEND_URL}/nonce`, {
        credentials: 'include',
      });
      const message = new SiweMessage({
        domain: window.location.host,
        address,
        statement,
        uri: window.location.origin,
        version: '1',
        chainId: chain?.id,
        nonce: await res.text()
      });
      console.log('nonce: ', message.nonce)
      return message.prepareMessage();
  }

  const signInWithEthereum = async function() {
      try {
        const message = await createSiweMessage(
          userAddress,
          'Sign in with Ethereum to the app.'
        );
        const signature = await signer?.signMessage(message);
        console.log('signature: ', signature)
        const res = await fetch(`${DEFAULT_BACKEND_URL}/verify`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message, signature }),
          credentials: 'include'
        });
        const data = await res.json()
        if (data.address == userAddress) {
          console.log('authenticated: ', data)
          setIsBackAuth(true)
        } else {
          console.log('non authenticated: ', data)
          setIsBackAuth(false)
        }
      } catch (e) {
        console.error(e)
      }
  }

  const getSession = async function () {
      try {
        const res = await fetch(`${DEFAULT_BACKEND_URL}/session`, {
          credentials: 'include',
        })
        const user = await res.json()
        if (user.address) {
          console.log("user: ", user)
          setIsBackAuth(true)
          await updateProfile()
          Cookie.setNickname(user.name)
          Cookie.setPlayerHash(user.address)
        } else {
          setIsBackAuth(false)
        }
      } catch (e) {
        console.log("session error: ", e)
      }
  }

  const updateProfile = async () => {
    try {
      const user = await axios.get(`${DEFAULT_BACKEND_URL}/users/${userAddress}`)
      setDescription(user.data.description)
      setName(user.data.name)
      if (user.data.avatar) {
        axios.get(`${DEFAULT_BACKEND_URL}/users/${userAddress}/downloadAvatar`, {responseType: 'arraybuffer'}).then(image => {
          let raw = Buffer.from(image.data).toString('base64');
          const base64 =  "data:" + image.headers["content-type"] + ";base64,"+raw;
          setAvatar(base64)
        })
      }
    } catch (e) {
      console.error(e)
      alert(e)
      setIsLoading(false)
      return
    }
  }

  const updateRoomsStats = async () => {
    const stats = await axios.get(`${DEFAULT_BACKEND_URL}/rooms/stats`)
    if (stats.data) {
      setRoomsCount(stats.data.rooms)
      setPlayersCount(stats.data.players)
    }
  }

  const signOut = async () => {
    const res = await fetch(`${DEFAULT_BACKEND_URL}/logout`, {
      credentials: 'include',
    })
    if (res.ok) {
      setIsBackAuth(false)
    }
  }

  useEffect(() => {
    if (address) {
      setUserAddress(address)
      updateProfile().then()
    }
  }, [address])

  useEffect(() => {
    getSession().then()
  }, [isBackAuth])

  useEffect(() => {
    updateRoomsStats().then()
  }, [])

  return (
    <>
      <BrowserRouter>
        <AddMoney isOpen={balanceModal.isOpen} balance={balance.toString()} deposit={transferBusd}
                  onClose={() => setBalanceModal({isOpen: false, type: "add-money"})} type={balanceModal.type}/>
        <WithdrawMoney isOpen={profileBalanceModal.isOpen} profileBalance={profileBalance.toString()} withdraw={withdrawBusd}
                       onClose={() => setProfileBalanceModal({isOpen: false, type: "withdraw"})}
                       type={profileBalanceModal.type}/>
        {isLoading && <Loader/>}
        <Container>
          <Routes>
            <Route path="/" element={<Layout
                                             isBackAuth = {isBackAuth}
                                             signOut = {signOut}
                                             signInWithEthereum={signInWithEthereum}
                                             isConnected={!isDisconnected}
                                             setBalanceModal={setBalanceModal}/>}>
              <Route index
                     element={<Main langs={langs} selectedLang={selectedLang} setSelectedLang={setSelectedLang}
                                    isBackAuth={isBackAuth} isConnected={!isDisconnected} rooms={roomsCount} players={playersCount} profileBalance={parseFloat(profileBalance)}/>}/>
              <Route path="game-list" element={<GameList playersCount={playersCount} />}/>
              <Route path="login" element={<Login/>}/>
              <Route path="home" element={<Home setBalanceModal={setBalanceModal}
                                                setProfileBalanceModal={setProfileBalanceModal}
                                                isBackAuth={isBackAuth}
                                                name={name}
                                                isConnected={!isDisconnected}
                                                updateNickname={updateNickname}
                                                avatar={avatar}
                                                uploadAvatar={uploadAvatar}
                                                description={description}
                                                updateDescription={updateDescription}
                                                address={userAddress} balance={balance}
                                                profileBalance={profileBalance}
                                                setIsLoading={setIsLoading}/>}/>
            </Route>
          </Routes>
        </Container>
      </BrowserRouter>
    </>
  )
}

export default App
