import classNames from 'classnames/bind';
import styles from "./Badge.module.css"
import {ReactNode} from "react";


type BadgeProps = {
  children: ReactNode,
}

function Badge({children}: BadgeProps) {
  const cx = classNames.bind(styles);

  return (
    <div className={cx("badge")} >
      {children}
    </div>
  )
}


export default Badge