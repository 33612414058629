import classNames from 'classnames/bind';
import styles from "./Table.module.css"
import {TableColumns, TableData} from "../../models/Table";


type TableProps = {
  tableColumns: TableColumns,
  tableData: TableData,
}

function Table({tableColumns, tableData}: TableProps) {
  const cx = classNames.bind(styles);

  return (
    <div className={cx("table-wrap")}>
      <div className={cx("table")}>
        <div className={cx("table__header")}>
          {tableColumns.map((column, index) =>
            <span style={{width: column.width}} className={cx("table__header__item")} key={index}>
              {column.title}
            </span>
          )}
        </div>
        {tableData.map((row, index) =>
          <div className={cx("table__row")} key={index}>
            {Object.keys(row).map((key, i) => {
              const column = tableColumns.find(item=>item.name===key)
              return (
                <div style={{width: column?.width}} className={cx("table__row__item")} key={i}>
                  {column?.type === "win-lose" &&
                    <span className={cx({"red": row[key] === "Lose", "green": row[key] === "Win"})}>
                      {row[key]}
                    </span>
                  }
                  {column?.type === "image" && <img className={cx("img")} src={row[key]} alt="Image"/>}
                  {column?.type === "text" && row[key]}
                  {column?.type === "number" && row[key]}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}


export default Table